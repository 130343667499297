.aboutUs-main-container {
  /* background-color: #eeeeee;
  height: auto; */
}

.aboutUs-main-img {
  height: 320px;
  width: 100%;
  position: relative;
}

.aboutUs-main-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.banner-opaque {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  padding-bottom: 45px;
  background-color: #0000006e;
  /* position: relative; */
}

.banner-text {
  color: white;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.below-banner-container {
  height: auto;
  background-color: #eeeeee;
  overflow: hidden;
  padding: 80px 50px 150px;
  position: relative;
}

.below-banner-container .element1 {
  height: 700px;
  position: absolute;
  top: -250px;
  left: -240px;
}

.below-banner-container .element2 {
  height: 800px;
  position: absolute;
  bottom: -280px;
  right: -280px;
}

.aboutUs-container1 {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
}

.aboutUs-head {
  font-size: 20px;
  text-transform: uppercase;
  color: #37466f;
  font-weight: bold;
  text-align: left;
}

.aboutUs-left-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.aboutUs-left-text span {
  text-align: justify;
}

.aboutUs-right-text {
  z-index: 1;
}

.aboutUs-right-text img {
  margin-top: 35px;
  height: 460px;
}

.aboutUs-second-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.aboutUs-second-container span {
  text-align: justify;
}

.aboutUs-third-container {
  background-color: #f5f5f5;
  height: auto;
  width: 100%;
  position: relative;
}

.aboutUs-counts-container {
  position: absolute;
  transform: translate(9%, -80px);
  z-index: 1;
  background: transparent linear-gradient(90deg, #e0bdfc 0%, #b2defb 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 35px;
  height: 160px;
  width: 85%;
  padding: 20px;
}

.aboutUs-counts-inner-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.aboutUs-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #37466f;
}

.counts {
  font-size: 35px;
  font-weight: bolder;
  letter-spacing: 1px;
}

.counts-text {
  font-size: 25px;
  letter-spacing: 1px;
}

.aboutUs-third-inner-container {
  height: 100%;
  padding: 180px 50px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}

.aboutUs-third-inner-container img {
  margin: 10px 0px 70px;
  padding: 0px 30px;
  width: 100%;
}

.contactUs-link {
  background: #37466f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  padding: 6px 30px;
  font-size: 17px;
  border: none;
}

.contactUs-link:hover {
  color: #37466f;
  background-color: white;
}

.contact-us-end-text {
  font-weight: bold;
  color: #393939;
  font-size: 20px;
  text-align: justify;
}

.leap-text {
  font-weight: bold;
  color: #393939;
  font-size: 18px;
}

.aboutUs-mission-vission {
  padding: 100px 180px 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.aboutUs-mission-vission img {
  height: 110px;
}

.mission-vission-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 70px;
}

.mission-container {
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mission-heading {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 19px;
  color: #37466f;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .contactUs-link {
    font-size: 13px;
    padding: 3px 12px;
  }

  .aboutUs-main-img {
    height: 140px;
  }

  .banner-opaque {
    padding-bottom: 0px;
  }

  .banner-text {
    font-size: 25px;
    margin-bottom: 5px;
  }

  .below-banner-container {
    padding: 50px 25px 80px;
  }

  .aboutUs-container1 {
    gap: 0;
  }

  .aboutUs-right-text img {
    display: none;
  }

  .aboutUs-head {
    font-size: 17px;
  }

  .aboutUs-mission-vission {
    padding: 50px 25px 85px;
    gap: 56px;
  }

  .mission-vission-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .aboutUs-mission-vission img {
    height: 80px;
  }

  .aboutUs-left-text span {
    font-size: 14px;
  }

  .aboutUs-second-container {
    font-size: 14px;
  }

  .mission-heading {
    font-size: 17px;
  }

  .aboutUs-counts-container {
    height: 83px;
    width: 90%;
    transform: translate(6%, -46px);
    padding: 10px;
    border-radius: 15px;
  }

  .counts {
    font-size: 17px;
  }

  .counts-text {
    font-size: 12px;
    letter-spacing: 0.2px;
  }

  .aboutUs-count {
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }

  .mission-container {
    gap: 5px;
  }

  .aboutUs-third-inner-container {
    padding: 100px 25px 50px;
    gap: 20px;
  }

  .aboutUs-third-inner-container img {
    margin: 10px 0px 13px;
    padding: 0px;
  }

  .leap-text {
    font-size: 12px;
  }

  .contact-us-end-text {
    font-size: 12px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .below-banner-container {
    padding: 80px 50px 70px;
  }

  .aboutUs-mission-vission {
    padding: 100px 86px 190px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1280px) {
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .aboutUs-left-text span {
    font-size: 19px;
  }

  .aboutUs-second-container span {
    font-size: 19px;
  }
  .aboutUs-head span {
    font-size: 25px;
  }

  .aboutUs-left-text {
    gap: 30px;
  }

  .aboutUs-second-container {
    gap: 30px;
  }

  .aboutUs-right-text img {
    height: 486px;
  }

  .aboutUs-counts-container {
    transform: translate(9%, -92px);
    height: 191px;
  }

  .aboutUs-third-container {
    height: 800px;
  }

  .contactUs-link {
    font-size: 20px;
  }

  .aboutUs-main-img {
    height: 380px;
  }

  .banner-opaque {
    padding-bottom: 75px;
  }

  .banner-text {
    font-size: 50px;
  }

  .breadcrumb-item {
    font-size: 20px !important;
  }
}
