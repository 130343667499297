@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
* {
  font-family: "Jost", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.breadcrumb-item {
  color: white;
  font-size: 14px;
}

.breadcrumb-item a:hover {
  color: #fc7171;
}

.breadcrumb-item a {
  color: white;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: white !important;
  /* padding-left: 3px !important; */
}

.breadcrumb-item::before {
  color: white !important;
  /* padding-right: 3px !important; */
}

.form-label {
  width: 100%;
  text-align: left;
  color: #37466f;
  font-weight: bold;
  letter-spacing: 0.8px;
}

.form-control,
.form-select {
  border: none !important;
  background-color: transparent !important;
  border-bottom: 1px solid #707070 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}

.form-control[type="textarea"],
textarea.form-control {
  border: 1px solid #707070 !important;
}

.form-text {
  display: block;
  width: 100%;
  text-align: left;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .breadcrumb-item {
    font-size: 10px;
  }
}
