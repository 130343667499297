.service-main-container {
  background-image: url("../../Assests/Services/serviceInnerElement.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 80px;
}

.idms-head-text {
  color: #0c0c0c;
  font-weight: 500;
  font-size: 35px;
  text-align: justify;
  /* padding-right: 90px; */
}

.idms0 {
  height: 330px;
}

.idms01 {
  margin-bottom: 50px;
}

.idms01 img {
  height: 300px;
}

.idms-service-second-container {
  background-color: #060b23;
  background-image: url("../../Assests/Services/element2.png");
  background-position: calc(50% - 130px) center;
  background-repeat: no-repeat;
  background-size: 190%;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100vh;
  text-align: center;
}

.service-second-heading {
  color: white;
  font-weight: 300;
  font-size: 40;
}

.service-sub-heading {
  text-align: center;
  color: white;
  width: 100%;
  letter-spacing: 0.7px;
  font-weight: 300;
  font-size: 30px;
  /* margin-top: 100px; */
}

.service-third-heading {
  color: #37466f;
  font-weight: 400;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ldms-images img {
  width: 95%;
}

.idms-descrip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
}

.idms-service-fourth-container {
  background-color: #e4e4e4;
  background-image: url("../../Assests/Services/element3.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 50px;
  height: 100vh;
  text-align: center;
}

.achievement-border {
  width: 220px;
  border-bottom: 2px solid #37466f;
}

.achievements-text {
  color: #37466f;
  font-size: 30px;
  letter-spacing: 0.8px;
  text-align: justify;
  line-height: 60px;
  width: 70%;
}

.green-highlight {
  color: #127558;
  font-weight: bold;
  font-size: 32px;
}

.achievement-container {
  height: calc(100% - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.idms-infograph {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.idms-infograph img {
  height: 250px;
}

.idms-infograph span {
  width: 75%;
  font-size: 20px;
  font-weight: 500;
}

.idms-service-fifth-container {
  height: 100vh;
}

.idms-service-sixth-container {
  background-color: #e4e4e4;
  padding: 50px;
  /* height: 100vh; */
  text-align: center;
}

.info-image-idms {
  height: 450px;
}

.idms-service-seventh-container {
  background-color: #eeeeee;
  padding: 50px;
  /* height: 100vh; */
  text-align: center;
}

.idms6-images {
  width: 300px;
}

.idms-service-last-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 90px;
  align-items: center;
  height: 100vh;
}

.client-note-container {
  position: relative;
  height: 500px;
}

.client-note-container img {
  height: 100%;
}

.client-note {
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 90px;
  padding: 70px 40px;
  height: 100%;
}

.client-note-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 35px;
}

.client-note-text span {
  text-align: justify;
  font-size: 18px;
}

.client-note img {
  height: 150px;
}

.idms-client-story {
  width: 100%;
  font-size: 40px;
  font-weight: 400;
  color: #37466f;
  text-align: left;
  padding-left: 30px;
}

.mad-info-1 {
  padding: 20px 70px 10px;
}

.mad-info-1 img {
  width: 100%;
}

.below-banner-mad-container {
  background-color: #eeeeee;
  background-image: url("../../Assests/Services/serviceInnerElement.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 50px 70px 40px;
}

.service-para {
  font-size: 20px;
  text-align: justify;
}

.mad-service-second-container {
  background-color: #e4e4e4;
  background-image: url("../../Assests/Services/serviceInnerElement.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 50px 70px;
  /* height: 100vh; */
}

.mad-service-info2 {
  width: 100%;
}

.mad-service-info2 img {
  width: 100%;
}

.mad-third-heading {
  font-size: 24px;
  text-align: left;
  align-items: flex-start;
  font-weight: 600;
}

.mad-service-third-container {
  background-color: #060b23;
  padding: 90px 100px 40px;
  color: white;
  font-size: 35px;
  letter-spacing: 0.6px;
}

.dls-image1 img {
  width: 95%;
}

.dls-second-heading {
  font-size: 30px;
  font-weight: bold;
}

.dls-service-info2 {
  padding: 60px 100px 0px;
}

.dls-service-info2 img {
  width: 100%;
}

.dls-sub-heading {
  font-size: 30px;
}

.dls-service-info3 {
  padding: 60px 0px 20px;
}

.dls-service-info3 img {
  width: 100%;
}

.dls-projects-container {
  font-size: 20px;
  font-weight: 500;
}

.dls-projects-container .row .col {
  margin-bottom: 80px;
}
/* 
.dls-projects-container img {
  height: 300px;
  width: auto;
} */

.dls-projects-sub-head {
  margin-bottom: 70px;
}

.pmc-approach-points-container {
  font-size: 20px;
}

.pmc-approach-points-heading {
  color: #37466f;
  font-weight: bold;
  margin-bottom: 10px;
}

.pmc-approach-points-label {
  color: #37466f;
  font-weight: bold;
}

.pmc-approach-points {
  font-size: 18px;
  text-align: justify;
}

.pmc-expertise-info {
  margin: 60px 0px 30px;
  gap: 35px;
}

.pmc-expertise-info img {
  height: 300px;
}

.pd-first-info {
  gap: 30px;
  width: 100%;
  margin: 60px 0px;
}

.pd-first-info div {
  width: 100%;
}

.pd-first-info div img {
  width: 100%;
}

.pd-serve {
  margin: 70px 0px 30px;
}

.pd-industries-serve .row .col-12 img {
  width: 300px;
}

.pd-industry-label {
  margin-top: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #37466f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pd-industry-label span {
  width: 80%;
}

.bullet-point {
  background-color: #37466f;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 10px 10px 0px 10px;
}

.sis-expertise {
  margin-bottom: 90px;
}

.arrow-point img {
  width: 30px;
  height: 15px;
  margin-right: 10px;
  margin-left: 30px;
}

.why-sis-container {
  margin-bottom: 90px;
}

.cloud-sub-heading {
  margin-top: 70px;
  font-size: 22px;
  font-weight: bold;
}

.cloud-info-container {
  padding: 0px 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 150px;
  margin-bottom: 150px;
}

.cloud-info-inner-container {
  position: relative;
}

.cloud-info-inner-container img {
  width: 150px;
}

.cloud-info {
  position: absolute;
  top: -110px;
  left: -70px;
  font-size: 20px;
  width: 300px;
}

.cloud-info1 {
  position: absolute;
  bottom: -110px;
  left: -70px;
  font-size: 20px;
  width: 300px;
}

.cloud-info-text {
  font-weight: bold;
}

.cloud-sub-head2 {
  margin-top: 0px;
}

.cloud-points-container .arrow-point img {
  width: 20px;
  height: 16px;
  margin-left: 10px;
}

.cloud-point-txt {
  font-weight: 600;
}

.cloud-client-name-list {
  margin: 40px 0px;
  padding: 0px 80px;
  color: #37466f;
  font-weight: bold;
  font-size: 20px;
}

.iot-service-second-container {
  padding: 50px 30px;
}

.iot-tabs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}

.iot-tab {
  width: 100%;
  background-color: #f2f3f5;
  position: relative;
  border-radius: 10px;
  height: 60px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgb(139, 139, 139);
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  scale: 1;
  transition: scale 0.3s ease-in-out;
}

.iot-tab:hover {
  scale: 1.04;
}

.iot-tab::before,
.iot-tab::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  z-index: 1;
}

.iot-tab::before {
  left: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.iot-tab::after {
  right: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.iot-tab1::before {
  background: linear-gradient(to right, #ffe37c, #feca0d);
}

.iot-tab1::after {
  background: linear-gradient(to right, #feca0d, #ffe37c);
}

.iot-tab2::before {
  background: linear-gradient(to right, #f4b24f, #ef7f31);
}

.iot-tab2::after {
  background: linear-gradient(to right, #ef7f31, #f4b24f);
}

.iot-tab3::before {
  background: linear-gradient(to right, #86c276, #529d52);
}

.iot-tab3::after {
  background: linear-gradient(to right, #529d52, #86c276);
}

.iot-tab4::before {
  background: linear-gradient(to right, #36a370, #198645);
}

.iot-tab4::after {
  background: linear-gradient(to right, #198645, #36a370);
}

.iot-tab5::before {
  background: linear-gradient(to right, #88ccd0, #28a6bd);
}

.iot-tab5::after {
  background: linear-gradient(to right, #28a6bd, #88ccd0);
}

.iot-tab6::before {
  background: linear-gradient(to right, #5084ad, #346594);
}

.iot-tab6::after {
  background: linear-gradient(to right, #346594, #5084ad);
}

.iot-tab7::before {
  background: linear-gradient(to right, #e57dac, #e04595);
}

.iot-tab7::after {
  background: linear-gradient(to right, #e04595, #e57dac);
}

.iot-info-first .col img {
  width: 100%;
  height: 300px;
}

.iot-info-first .col svg {
  width: 100%;
  height: 300px;
}

.iot-info-content {
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 15px;
  height: 300px;
}

.iot-info-title {
  font-size: 19px;
  font-weight: bold;
  padding: 5px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iot-info-descrip {
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  border-radius: 0px 0px 15px 15px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iot-info-descrip {
  background-color: white;
}

.iot-info-title {
  border-radius: 14px 14px 0px 0px;
}

.iot-info1-title {
  background-color: #ffc900;
}

.iot-info2-title {
  background-color: #ff71a3;
}

.iot-info3-title {
  background-color: #8dda98;
}

.iot-info4-title {
  background-color: #e574f7;
}

.iot-info2-content {
  padding: 3px;
  border-radius: 15px;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.iot-info-title2 {
  position: relative;
  background-color: white;
  border-radius: 12px 12px 0 0;
  height: 60px;
}

.iot-info-title2 div {
  position: relative;
  z-index: 9999999;
  font-size: 17px;
  font-weight: bold;
  padding-top: 3px;
}

.iot-info-title2::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 20px; /* Adjust this value to change the concave depth */
  background: white;
  border-radius: 0 0 50% 50%;
  transform: translateY(50%);
  z-index: 0;
}

.iot-info2-descrip {
  height: 100%;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-radius: 0px 0px 15px 15px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.iot-info2-content1 {
  background-color: #206c66;
}

.iot-info2-content2 {
  background-color: #144860;
}

.iot-info2-content3 {
  background-color: #323996;
}

.iot-info2-content4 {
  background-color: #5c00af;
}

.iot-info2-content5 {
  background-color: #7736a6;
}

.iot-info-second {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.iot-info-second .iot-second-infoo {
  width: 100%;
}

.ui-ux-info-container {
  margin-bottom: 90px;
}

.ui-ux-info-container .col img {
  width: 160px;
  margin-bottom: 10px;
}

.ui-info-title {
  /* font-size: 17px; */
  font-weight: bold;
  margin-bottom: 5px;
}

.ui-info-descrip {
  font-weight: 400;
  text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .idms-head-text {
    font-size: 17px;
  }

  .idms0 {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  .service-inner-container {
    padding: 0px 30px !important;
  }

  .idms-service-second-container {
    height: auto;
  }

  .idms01 {
    margin-bottom: 38px;
  }

  .idms01 img {
    width: 100%;
    height: auto;
  }

  .service-sub-heading {
    font-size: 20px;
  }

  .service-third-heading {
    font-size: 20px;
  }

  .idms-descrip {
    margin-top: 20px;
    padding-left: 0px;
  }

  .service-para {
    font-size: 14px;
  }

  .green-highlight {
    font-size: 20px;
  }

  .achievement-container {
    height: auto;
  }

  .achievement-container .row {
    gap: 50px 0px;
  }

  .achievements-text {
    width: 100%;
    line-height: normal;
    letter-spacing: normal;
    font-size: 17px;
  }

  .idms-service-fourth-container {
    padding: 50px 25px;
    height: auto;
  }

  .idms-service-fifth-container {
    height: auto;
  }

  .idms-infograph img {
    height: 198px;
  }

  .idms-infograph {
    gap: 10px;
  }

  .idms-infograph span {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .service-main-container {
    background-repeat: repeat-y;
  }

  .info-image-idms {
    height: 179px;
  }

  .idms-service-sixth-container div {
    gap: 10px !important;
  }

  .idms-service-seventh-container {
    padding: 50px 25px;
  }

  .idms-service-seventh-container div {
    gap: 10px !important;
  }

  .idms6-images {
    width: 90px;
  }

  .idms-service-last-container {
    flex-direction: column;
  }

  .client-note {
    padding: 20px;
  }

  .client-note-text span {
    font-size: 7px;
  }

  .client-note img {
    height: 57px;
  }

  .idms-service-sixth-container {
    padding: 50px 25px;
  }

  .idms-client-story {
    font-size: 22px;
    padding-left: 0px;
    text-align: center;
  }

  .client-note-container {
    height: 170px;
  }

  .idms-service-last-container {
    height: auto;
    justify-content: center;
    gap: 30px;
  }

  .below-banner-mad-container {
    padding: 50px 25px;
  }

  .cloud-info-inner-container img {
    width: 46px;
  }

  .cloud-info-container {
    gap: 6px;
    padding: 0px;
    margin-top: 67px;
    margin-bottom: 47px;
  }

  .cloud-info {
    top: -37px;
    left: -25px;
    font-size: 6px;
    width: 100px;
  }

  .cloud-info1 {
    top: 58px;
    left: -25px;
    font-size: 6px;
    width: 100px;
  }

  .mad-service-second-container {
    padding: 50px 25px;
  }

  .cloud-points-container .arrow-point img {
    width: 11px;
    height: 10px;
    margin-left: 5px;
  }

  .pmc-approach-points-heading {
    font-size: 17px;
  }

  .why-sis-container {
    margin-bottom: 21px;
  }

  .cloud-client-name-list {
    padding: 0px 20px;
    font-size: 9px;
  }

  .mad-info-1 {
    padding: 10px 0px 0px;
  }

  .mad-service-third-container {
    padding: 50px 25px;
    font-size: 20px;
  }

  .mad-service-third-container .row .col-lg-5 {
    margin-bottom: 15px;
  }

  .dls-second-heading {
    font-size: 19px;
    margin-bottom: 20px !important;
  }

  .dls-service-info2 {
    padding: 20px 0px 0px 0px;
  }

  .dls-sub-heading {
    font-size: 19px;
  }

  .dls-service-info3 {
    padding: 25px 0px 0px;
  }

  .dls-projects-container {
    font-size: 11px;
  }

  .dls-projects-container .row .col {
    margin-bottom: 20px;
  }

  .dls-image1 img {
    margin-top: 30px;
  }

  .dls-projects-sub-head {
    margin-bottom: 45px;
    text-align: left;
  }

  .pmc-approach-points {
    font-size: 14px;
  }

  .pmc-approach-points-label {
    font-size: 14px;
  }

  .pmc-expertise-info {
    margin: 0px 0px 10px;
    gap: 6px;
  }

  .pmc-expertise-info img {
    height: 76px;
  }

  .pd-first-info {
    gap: 6px;
    margin: 30px 0px 0px !important;
    padding: 0px !important;
  }

  .pd-industries-serve {
    padding: 0px !important;
  }

  .pd-industries-serve .row {
    padding: 0px !important;
    margin: 0px !important;
  }

  .pd-industries-serve .row .col-12 img {
    width: 100%;
  }

  .pd-industry-label span {
    width: 100%;
    margin-bottom: 40px;
    font-size: 20px;
  }

  .bullet-point {
    width: 21px;
    height: 5px;
    margin: 10px 5px 0px 10px;
  }

  .sis-expertise {
    margin-bottom: 30px !important;
  }

  .arrow-point img {
    width: 11px;
    height: 7px;
    margin-right: 7px;
    margin-left: 0px;
  }

  .ui-ux-info-container {
    gap: 50px 0px;
  }

  .ui-info-descrip {
    text-align: justify;
  }

  .below-banner-mad-container form .row {
    padding: 0px !important;
    margin: 0px !important;
  }

  .below-banner-mad-container form .row .col-lg-12 {
    margin-bottom: 30px !important;
    padding: 0px !important;
  }

  .below-banner-mad-container form .row .col-lg-6 {
    margin-bottom: 30px !important;
    padding: 0px !important;
  }

  .iot-tabs-container {
    width: 100%;
  }

  .iot-service-second-container .col-9 {
    display: none;
  }

  .iot-service-second-container .contact-us-end-container {
    padding: 0px !important;
  }

  /* .pd-industries-serve .row .col-12 img {
    width: 100%;
  } */
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .idms01 img {
    height: 271px;
  }

  .achievements-text {
    width: 93%;
  }

  .idms6-images {
    width: 275px;
  }

  .client-note-container {
    height: 411px;
  }

  .client-note-text {
    gap: 15px;
  }

  .cloud-info-container {
    gap: 20px;
  }

  .iot-tab {
    font-size: 11px;
  }

  .iot-info-title {
    font-size: 15px;
  }

  .iot-info-descrip {
    font-weight: normal;
    font-size: 13px;
    padding: 5px 10px;
  }

  .iot-info-title2 div {
    font-size: 13px;
  }

  .iot-info2-descrip {
    font-size: 13px;
    font-weight: normal;
    padding: 5px 10px;
  }

  .pmc-expertise-info img {
    height: 224px;
  }

  .pmc-expertise-info {
    margin: 34px 0px 16px;
    gap: 22px;
  }

  .pd-industries-serve .row .col-12 img {
    width: 240px;
  }

  .pd-industry-label {
    font-size: 19px;
  }

  .depo-product-img-container {
    gap: 70px;
  }

  .depo-img {
    gap: 70px;
  }

  .depo-img img {
    height: 270px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .idms01 img {
    height: 380px;
  }

  .idms01 {
    margin-bottom: 73px;
  }

  .idms-service-fifth-container {
    height: 88vh;
  }

  .idms-service-last-container {
    gap: 40px;
  }

  .pmc-expertise-info {
    gap: 17px;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
}
