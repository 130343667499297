.domain-main-container {
  background-image: url("../../Assests/Domains/elements1.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 80px;
}

.domain-info1-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
}

.node-img img {
  height: 200px;
  width: 300px;
  /* object-fit: contain; */
  border-radius: 10px;
}

.domain-descrip-container {
  justify-content: center;
  margin-bottom: 60px;
}

.domain-opaque-film {
  border-radius: 40px;
}

.aviaInfo-img img {
  height: 300px;
  width: 350px;
  border-radius: 15px;
  /* object-fit: contain; */
}

.aviation-descrip-container {
  margin-bottom: 150px;
}

/* Agriculture css */
.flipcards-main-container {
  margin: 50px 0px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
  row-gap: 40px;
}

.flipcards-agri-container {
  width: 100%;
  /* margin: 10px; */
  /* display: inline-block; */
}

.front-card {
  width: 100%;
  height: 250px;
  /* border: 1px solid grey; */
  background-color: white;
  border-radius: 40px;
  color: black;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 9px;
}

.back-card {
  width: 100%;
  height: 250px;
  border: 1px solid white;
  background-color: #37466f;
  border-radius: 40px;
  color: white;
  font-weight: 500;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.front-card-inside {
  padding: 20px;
}

.domain-banner-heading {
  font-size: 45px;
  /* line-height: 80px; */
  font-weight: 500;
  letter-spacing: 0px;
  color: #0c0c0c;
}
.domain-inner-container {
  text-align: justify;
  margin-top: 65px;
  padding: 0px 50px;
  padding-bottom: 80px;
  border-bottom: 3px solid rgba(112, 112, 112, 0.43);
}
.domain-para {
  font-size: 22px;
  /* line-height: 35px; */
  font-weight: 400;
  letter-spacing: 0px;
  color: #0c0c0c;
  margin-bottom: 125px;
}

.domain-subpara {
  font-size: 22px;
  /* line-height: 35px; */
  font-weight: 400;
  /* letter-spacing: 0px; */
  color: #0c0c0c;
  margin-bottom: 65px;
}

.domain-info1 {
  margin: 0px 0px 20px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.domain-info1-img .opaque-screen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #57575778;
}

.domain-info1-img {
  text-align: center;
  margin: 1% 0px 120px;
  position: relative;
  width: 600px;
  height: 300px;
}

.domain-info1-img .mining {
  text-align: center;
  margin: 1% 0px 120px;
  position: relative;
  width: 800px;
  height: 450px;
}

.circle-anim .discover-now-btn {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  position: relative;
  justify-content: flex-start;
}

.circle-anim .discover-now-btn .step {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  /* overflow: hidden; */
  transition: width 0.4s ease, border-radius 0.4s ease;
}

.circle-anim .discover-now-btn .step:hover {
  width: 50px;
  border-radius: 30px;
}

.circle-anim .discover-now-btn .step:hover svg {
  display: block;
  opacity: 1;
}

.domain-info1 .circle-anim {
  position: relative;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.domain-descrip-left {
  width: 500px;
  height: 300px;
}

.domain-descrip-left .sub-heading-product {
  text-align: left;
}

.domain-descrip-right {
  height: 300px;
}

/* .miningDomain .contact-us-end-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 95px;
} */

.explore-domains {
  width: 100%;
  padding: 35px 0px;
}

.explore-domains .heading h2 {
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0px;
  color: #0c0c0c;
}

.domain-subheading h3 {
  font-size: 50px;
  /* line-height: 65px; */
  font-weight: 400;
  /* letter-spacing: 2px; */
  color: #000000;
  text-align: center;
  margin-bottom: 85px;
}

.infograph-out {
  display: flex;
  justify-content: flex-end;
}

.infograph {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.infograph-text span {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0c0c0c;
}

.domain-descrip {
  margin-bottom: 100px;
}

.evDomain-descrip-left {
  height: 350px;
}

.ecomm-img img {
  width: 180px;
  height: 180px;
}

.ecomm-img {
  gap: 72px;
}

.mining-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mining-img img {
  width: 400px;
}

.card-content-img {
  width: 100%;
}

.domain-explore-carousel-container {
  padding: 0px 20px;
  margin-top: 40px;
}

.domain-explore-image {
  width: 300px;
  position: relative;
  cursor: pointer;
}

.domain-explore-image img {
  width: 100%;
  height: auto;
}

.explore-domain-opq {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: white;
  font-weight: 500;
  font-size: 20px;
}

.domain-video-modal {
  padding: 5px;
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.domain-video-modal video {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.domain-video-modal div {
  margin: 0;
  font-size: 13px;
  width: 100%;
  text-align: right;
}

.slick-prev:before,
.slick-next:before {
  color: #37466f !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .domain-main-container {
    background-repeat: repeat-y;
  }

  .domain-info1 {
    padding: 0px 10px;
    margin: 0px;
  }

  .domain-info1-img {
    height: auto;
    margin: 0px 0px 60px 0px;
  }

  .sub-heading-product {
    font-size: 14px;
  }

  .node-img img {
    height: auto;
    width: 100%;
  }

  .domain-descrip-container {
    margin-bottom: 90px;
  }

  .explore-domains .heading h2 {
    font-size: 17px;
  }

  .domain-explore-carousel-container {
    margin-top: 20px;
  }

  .explore-domain-opq {
    justify-content: center;
    /* padding-left: 15px; */
    border-radius: 10px;
  }
  .domain-explore-image {
    width: 224px;
  }

  .explore-domains {
    padding: 18px 0px 0px;
  }

  .aviaInfo-img img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  .flipcards-main-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 10px;
  }

  .front-card {
    height: 190px;
    padding: 10px;
    border-radius: 17px;
    font-size: 14px;
  }

  .back-card {
    font-size: 12px;
    padding: 10px;
    border-radius: 17px;
    height: 190px;
    font-weight: normal;
  }

  .domain-banner-heading {
    font-size: 22px;
  }

  .domain-inner-container {
    padding: 0px 0px 50px 0px;
    margin-top: 40px;
  }

  .domain-para {
    font-size: 14px;
    margin-bottom: 42px;
  }

  .domain-descrip-left {
    width: 100%;
    height: auto;
  }

  .mining-img img {
    width: 100%;
  }

  .domain-descrip-right {
    height: auto;
    width: 100%;
  }

  .domain-inner-container .contact-us-end-container {
    margin-top: 32px;
  }

  .domain-inner-container .row .col-lg-6 {
    margin-top: 10px;
    margin-bottom: 0px !important;
  }

  .domain-inner-container .row .col-lg-12 {
    margin-top: 45px;
  }

  .domain-inner-container ul li {
    text-align: left;
  }

  .domain-inner-container .row .col-lg-6:nth-child(3),
  .domain-inner-container .row .col-lg-6:nth-child(5),
  .domain-inner-container .row .col-lg-6:nth-child(7) {
    margin-top: 60px;
  }

  .domain-subheading h3 {
    font-size: 22px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 50px;
  }

  .domain-info1-img img {
    border-radius: 20px;
  }

  .domain-info1-img .opaque-screen {
    border-radius: 20px;
  }

  .domain-subpara {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .ecomm-img {
    gap: 0px;
  }

  .ecomm-img img {
    width: 50px;
    height: 50px;
  }

  .infograph {
    width: auto;
    gap: 2px;
  }

  .infograph-text span {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .domain-explore-image {
    width: 232px;
  }

  .domain-inner-container {
    padding: 0px;
  }

  .domain-descrip-left {
    width: auto;
  }

  .domain-para {
    margin-bottom: 58px;
  }

  .domain-subheading h3 {
    font-size: 43px;
    margin-bottom: 60px;
  }

  .ecomm-img {
    gap: 8px;
  }

  .ecomm-img img {
    width: 140px;
    height: 140px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .ecomm-img {
    gap: 58px;
  }
}

@media screen and (min-width: 1532px) and (max-width: 1532px) {
  .new-ecomm-container-info {
    padding: 0px 95px;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
}
