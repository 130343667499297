.login-main-container {
  height: 100vh;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-inner-container {
  height: 70%;
  width: 500px;
  padding: 50px 40px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .login-inner-container {
    width: 80%;
  }
}
