.careers-main-container {
  margin-top: 50px;
}

.career-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
  /* padding: 0px 40px; */
}

.career-box-1 {
  background-color: white;
  border-radius: 14px;
  width: 30%;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
}

.career-box-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  width: 70%;
}

.career-box {
  background-color: white;
  border-radius: 14px;
  width: 100%;
  padding: 20px;
  position: relative;
  height: auto;
  box-shadow: 0px 3px 6px #00000029;
  text-align: left;
}

.blue-border {
  width: 100px;
  height: 6px;
  background-color: #37466f;
  position: absolute;
  top: -3px;
  left: 15px;
  border-radius: 6px;
}

.career-box-1 .form-control {
  background-color: #eeeeee !important;
  border: none !important;
  border-radius: 6px 0px 0px 6px !important;
}

.career-box-1 .input-group-text {
  background-color: #37466f;
  border: none;
  width: 60px;
}

.career-box-1 .input-group-text span {
  width: 100%;
}

.search-container label {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  font-weight: 500;
}

.grey-line {
  margin: 30px 0px 20px;
  background-color: #707070;
  width: 100%;
  height: 1px;
}

.sidebar-lib {
  text-align: left;
  border: none !important;
}

.sub-item-menu a {
  padding-left: 20px !important;
  flex-direction: row-reverse !important;
  gap: 20px !important;
  font-weight: 500;
}

.sub-item-menu .menu-item a {
  font-weight: normal;
}

.sub-item-menu .menu-item label input {
  margin-right: 10px;
}

.career-location {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.career-location span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: 13px;
}

.career-subhead {
  font-weight: 600;
  font-size: 17px;
  text-align: left;
  text-transform: capitalize;
}

.lightGrey-line {
  margin: 10px 0px 20px;
  background-color: #dbdbdb;
  width: 100%;
  height: 1px;
}

.apply-now {
  margin-top: 20px;
}

.apply-now a {
  text-decoration: none;
  color: white;
  background: #37466f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  opacity: 1;
  padding: 4px 18px;
  font-size: 14px;
}

.apply-now button {
  text-decoration: none;
  color: white;
  border: none;
  background: #37466f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  opacity: 1;
  padding: 4px 18px;
  font-size: 14px;
  margin-bottom: 20px;
}

.applyNow-banner-container {
  padding: 50px;
}

.applyNow-main-container {
  position: relative;
  background-color: white;
  width: 100%;
  height: auto;
  border-radius: 15px;
  padding: 20px 30px;
  text-align: left;
}

.applyNow-main-container .blue-border {
  left: 30px;
}

.applyNow-sub-heading {
  font-size: 20px;
  font-weight: 500;
  border-left: 2px solid #37466f;
  padding-left: 10px;
  margin-bottom: 20px;
}

.applyNow-jd {
  margin-bottom: 30px;
  padding-left: 10px;
}

.applyNow-form .form-control {
  border: 2px solid #eeeeee !important;
}

.applyNow-form .form-select {
  border: 2px solid #eeeeee !important;
}

.upload-form-control {
  position: relative;
  color: #000000;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.thank-you-career {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thank-you-career div {
  font-size: 25px;
}

.thank-you-career p {
  position: absolute;
  bottom: -10px;
  left: 0;
  margin: 0;
  font-weight: normal;
  font-size: 13px;
}

.modal-career {
  border-radius: 5px;
  border: 5px solid #37466f;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .career-inner-container {
    flex-direction: column;
  }

  .career-box-1 {
    width: 100%;
  }

  .filters-container {
    font-size: 14px;
  }

  .search-container input {
    font-size: 14px;
  }

  .search-container label {
    font-size: 14px;
  }

  .career-box-2 {
    width: 100%;
  }

  .applyNow-banner-container {
    padding: 40px 20px;
  }

  .applyNow-main-container {
    padding: 15px;
  }

  .applyNow-form input {
    font-size: 14px;
  }

  .applyNow-form textarea {
    font-size: 14px;
  }

  .applyNow-jd {
    text-align: justify;
    padding: 0px 10px;
  }

  .applyNow-main-container .mb-4 {
    text-align: justify;
    padding: 0px 10px;
  }
}
