.product-below-banner-container {
  width: 100%;
  /* background-image: url("../../Assests/Products/productBackElement.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
  background-color: #f5f5f5;
}

.product-banner-heading {
  font-size: 45px;
  /* line-height: 80px; */
  font-weight: 500;
  color: #37466f;
  margin-bottom: 75px;
}

.product-inner-container {
  text-align: justify;
  margin-top: 40px;
  /* padding: 0px 50px; */
}

.product-heading {
  font-size: 36px;
  line-height: 50px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0c0c0c;
  text-transform: uppercase;
}

.product-subheading {
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0c0c0c;
}

.sub-heading-product {
  font-size: 22px;
  /* line-height: 50px; */
  font-weight: bold;
  letter-spacing: 0px;
  color: #0c0c0c;
}

.product-para {
  font-size: 18px;
  /* line-height: 39px; */
  font-weight: 400;
}

.product-info1-img {
  margin: 0px 0px 20px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-info1-img img {
  width: 100%;
}

.product-descrip-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 100px;
}

.product-descrip-heading {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.product-descrip-left {
  width: 600px;
}

.product-descrip-right img {
  /* width: 450px; */
  height: 500px;
}

.product-images {
  margin: 90px 0px 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* gap: 50px; */
}

.product-images img {
  height: 400px;
}

.tablet-img img {
  width: 100%;
  height: auto;
}

.product-abcd {
  margin: 100px 0px 80px;
}

.product-abcd-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
}

.contact-us-end-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}

/* Depo Management CSS */
.depo-product-img-container {
  display: flex;
  flex-direction: column;
  gap: 70px;
  padding: 0px 20px;
  margin-top: 70px;
}

.depo-img {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;
}

.depo-image2 {
  justify-content: flex-end;
  align-items: flex-end;
}

.depo-img img {
  height: 350px;
}

.depo-second-heading {
  margin-top: 80px;
}

.product-sptext h3 {
  font-size: 42px;
  line-height: 62px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #1b40a3;
  text-align: center;
}

.product-sptext h3 span {
  font-weight: 600;
}

.product-img {
  width: 100%;
  padding: 0px 30px;
}

.product-features {
  width: 100%;
  height: 500px;
  background-color: #ffffff;
  border-radius: 50px;
  text-align: center;
  padding-top: 75px;
  padding: 75px 50px 45px 50px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.product-features .features-heading {
  margin-bottom: 40px;
}

.product-features .features-heading span {
  font-size: 18px;
  line-height: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0c0c0c;
}
.product-features .features-para span {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #0c0c0c;
}
.product-features .fbox {
  width: 100px;
  height: 40px;
  border-radius: 30px 30px 0px 0px;
  transform: rotate(90deg) translateY(100px);
  position: absolute;
}

.product-features .fbox.one {
  background-color: #00a9b9;
}

.product-features .fbox.two {
  background-color: #10be81;
}

.product-video-container video {
  width: 900px;
}

.pVideo-two video {
  width: 600px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .product-heading {
    font-size: 17px;
  }

  .product-para {
    font-size: 14px;
    margin-top: 0px !important;
  }

  .product-banner-heading {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .product-descrip-left {
    width: 100%;
  }

  .product-descrip-container {
    flex-direction: column;
    gap: 20px;
  }

  .product-descrip-heading {
    margin-bottom: 10px;
  }

  .tablet-img img {
    width: 100%;
  }

  .product-descrip-right img {
    width: 100%;
    height: auto;
  }

  .product-inner-container {
    padding: 0px !important;
  }

  .product-info1-img {
    margin: 0px 0px 40px;
    padding: 0px;
  }

  .product-images {
    flex-direction: column;
    gap: 20px;
  }

  .product-below-banner-container {
    background-repeat: repeat;
    background-size: 100%;
  }

  .product-images {
    margin: 90px 0px;
  }

  .product-sptext {
    padding: 0px !important;
  }

  .product-sptext h3 {
    line-height: normal;
    font-size: 19px;
  }

  .product-subheading {
    font-size: 14px;
  }

  .product-img img {
    width: 100%;
  }

  .product-features {
    padding: 30px 20px;
    border-radius: 23px;
    height: auto;
  }

  .product-features .features-heading {
    margin-bottom: 20px;
  }

  .product-features .features-heading span {
    font-size: 17px;
    line-height: normal;
  }

  .product-features .features-para span {
    font-size: 14px;
    line-height: normal;
    text-align: justify;
  }

  .product-features .fbox {
    height: 40px;
    transform: rotate(90deg) translateY(76px);
  }

  .depo-img img {
    height: 123px;
  }

  .depo-img {
    gap: 5px;
  }

  .depo-product-img-container {
    padding: 0px;
    gap: 18px;
  }

  .product-video-container video {
    width: 360px;
  }

  .pVideo-two video {
    width: 360px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .product-descrip-container {
    gap: 73px;
  }

  .contact-us-end-container {
    margin-bottom: 54px;
  }
  .product-features {
    padding: 40px 50px 45px 40px;
  }

  .product-features .features-heading {
    margin-bottom: 23px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1280px) {
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .product-heading {
    font-size: 40px;
  }
}
