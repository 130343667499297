.aeonLogo {
  height: 70px;
  width: 70px;
}

.home-container {
  position: relative;
  height: 100vh;
  width: 100%;
  /* background-image: url("../Assests/homeImg.png"); */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-color: #000000bf; */
}

.opaque-screen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #000000bf;
}

.inner-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: white;
  /* position: absolute; */
  bottom: 120px;
  left: 140px;
}

.inner-container span {
  font-size: 18px;
}

.carousel-caption {
  top: 19rem !important;
  bottom: initial !important;
}

.carousel-item img {
  height: 100vh;
  object-fit: cover;
}

.discover-now-btn {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  position: relative;
  justify-content: flex-start;
}

/* .discover-now-btn button {
  border: none;
  padding: 0px 30px;
  height: 50px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  color: #37466f;
  font-weight: bold;
  font-size: 20px;
} */

.discover-now-text {
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  /* display: none; */
  font-size: 0;
  transition: opacity 1s ease-in-out;
}

.discover-now-btn .step {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  /* overflow: hidden; */
  transition: width 0.5s ease-in-out, border-radius 0.5s ease-in-out;
}

.discover-now-btn .step:hover {
  width: 200px;
  border-radius: 30px;
}

.step:hover .discover-now-text {
  color: #37466f;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  /* display: block; */
}

.step:hover .ripple,
.step:hover .ripple:nth-child(2),
.step:hover .ripple:nth-child(3) {
  display: none;
}

.step svg {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.4s ease;
  width: 30px;
  height: 30px;
}

.step:hover svg {
  display: none;
  opacity: 0;
}

.ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: white;
  opacity: 0;
  animation: ripple-animation 2s infinite cubic-bezier(0.65, 0, 0.34, 1);
}

.ripple:nth-child(2) {
  animation: ripple-animation1 2s infinite cubic-bezier(0.65, 0, 0.34, 1);
}

.ripple:nth-child(3) {
  animation: ripple-animation2 2s infinite cubic-bezier(0.65, 0, 0.34, 1);
}

@keyframes ripple-animation {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.4, 1.4, 1);
  }
}

@keyframes ripple-animation1 {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.6, 1.6, 1);
  }
}

@keyframes ripple-animation2 {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.8, 1.8, 1);
  }
}

/* About us css */
.aboutUs-container {
  height: 100vh;
  width: 100%;
  background-color: #eeeeee;
}

.aboutUs-inner-container {
  width: 100%;
  height: 100%;
  padding: 0px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUs-left {
  text-align: justify;
}

.sub-heading {
  text-transform: uppercase;
  font-weight: bold;
  color: #37466f;
  margin-bottom: 38px;
}

.heading-underline {
  display: block;
  height: 2px;
  width: 50px;
  background-color: #37466f;
}

.explore-more {
  width: 100%;
  text-align: right;
}

.explore-more a {
  background: #37466f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 8px 25px;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.explore-more a:hover {
  background-color: white;
  color: black;
}

.aboutUs-right {
  padding-right: 0px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.aboutUs-right img {
  /* height: 83%; */
  width: 90%;
}

/* Domains css */
.domains-container {
  height: calc(768px - 50px);
  width: 100%;
  background-color: #e1e1e1b0;
  padding-top: 30px;
  padding-bottom: 20px;
}

.domains-inner-container {
  height: calc(100% - 68.39px);
  overflow-y: auto;
  margin-right: 10px;
}

/* .domains-inner-container::-webkit-scrollbar {
  display: none;
} */

.domains-inner-container::-webkit-scrollbar {
  width: 6px;
  height: 100px; /* This controls the height of horizontal scrollbars */
}

.domains-inner-container::-webkit-scrollbar-track {
  background: transparent; /* No background for the scrollbar track */
}

.domains-inner-container::-webkit-scrollbar-thumb {
  background-color: #37466f; /* Blue scrollbar thumb */
  border-radius: 3px; /* Rounded corners for the scrollbar thumb */
}

/* Optional: styling for horizontal scrollbar if needed */
.domains-inner-container::-webkit-scrollbar-thumb:horizontal {
  height: 6px;
}

.domains-inner-container::-webkit-scrollbar-thumb:vertical {
  width: 6px;
  height: 100px; /* The height of the scrollbar thumb */
}

.domain-heading {
  padding-top: 20px;
  color: #37466f;
  font-weight: bold;
  text-transform: uppercase;
}

.domain-first {
  height: 100%;
  width: 100%;
  /* background-image: url("../Assests/domainElement.png"); */
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 85% auto;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 100px;
}

.domain-second {
  height: 100%;
  width: 100%;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 60px;
  /* margin-top: 60px; */
}

.domain-third {
  height: 100%;
  width: 100%;
  /* background-image: url("../Assests/domainElement2.png"); */
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 85% auto;
  padding: 20px 50px;
  /* margin-top: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 110px;
}

.domain {
  display: flex;
  flex-direction: row;
  gap: 0;
  /* margin-bottom: 60px; */
}

.domain img {
  height: 230px;
  object-fit: cover;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 0px 0px 50px;
}

.domain-right1 {
  width: 650px;
  background: transparent linear-gradient(270deg, #ffffff17 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  filter: drop-shadow(0px 3px 6px #00000029);
  text-align: left;
}

.domain-right1-inner {
  height: 230px;
  text-align: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.domain-right1-inner p {
  text-align: justify;
}

.domain-right1 a {
  text-decoration: none;
  color: #37466f;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  transition: letter-spacing 0.3s ease-in;
}

.domain-right1 a:hover {
  letter-spacing: 3px;
  transition: letter-spacing 0.5s ease-out;
}

.domain-right1 span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.domain-left-conatiner {
  justify-content: flex-end;
}

.domain-left1 {
  width: 650px;
  background: transparent linear-gradient(90deg, #ffffff17 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  filter: drop-shadow(0px 3px 6px #00000029);
  text-align: right;
}

.domain-left1-inner {
  height: 230px;
  text-align: right;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.domain-left1-inner p {
  text-align: justify;
}

.domain-left1 a {
  text-decoration: none;
  color: #37466f;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  transition: letter-spacing 0.3s ease-in;
}

.domain-left1 a:hover {
  letter-spacing: 3px;
  transition: letter-spacing 0.3s ease-out;
}

.domain-left1 span {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.domain-left-conatiner img {
  height: 230px;
  object-fit: cover;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 50px 0px 0px;
}

/* Services css */
.services-container {
  height: 100vh;
  width: 100%;
  background-color: #040c21;
  color: white;
  background-image: url("../Assests/Services/serviceElement.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 130%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  align-items: center;
  padding: 50px 0px;
}

.service-heading {
  padding-top: 20px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.service-inner-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.services-blog {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 200px;

  /* width: 100%; */
}

.service .polygonImg {
  height: 110px;
}

.service a {
  position: relative;
}

.service .polygonImg {
  cursor: pointer;
  scale: 1;
  transition: scale 0.5s ease;
}

.service a:hover .polygonImg {
  scale: 1.1;
}

.service .test-service {
  height: 100%;
  padding-bottom: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
}

.service .polygonService {
  height: 55px;
  width: auto;
}

.service .polygonService.active {
  height: 75px;
}

.service .small-service-img {
  height: 65px;
}

.service span {
  font-size: 16px;
  width: 220px;
}

.service-tile-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Products css */
.products-container {
  height: auto;
  width: 100%;
  background-color: #e1e1e1b0;
  padding: 70px 80px 60px;
  color: white;
  background-image: url("../Assests/Products/productElement.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.product-heading {
  color: #37466f;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.products-inner-container {
  margin-top: 60px;
}

.product-box-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.first-box-container {
  margin-bottom: 60px;
}

.product-box {
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 0.7;
  height: 400px;
  width: 100%;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: black;
}

.product-box img {
  height: 80px;
  /* width: 80px; */
  /* width: 80px; */
  /* padding: 5px; */
}

.product-box-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.product-box-inner span {
  /* padding-left: 10px; */
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.product-box-inner div {
  /* padding-left: 10px; */
  text-align: justify;
}

.product-box a {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #37466f;
  font-weight: bold;
  /* padding-left: 10px; */
}

.product-box .product-gold-img.active {
  /* height: 100px;
  width: 100px; */
  padding: 0;
}

.product-box:hover {
  /* background-color: #040c21; */
  background: transparent
    linear-gradient(233deg, #4c799f 0%, #446d91 13%, #101d32 67%, #070f22 100%)
    0% 0% no-repeat padding-box;
  color: white;
  opacity: 1;
}

.product-box:hover a {
  color: #fc7171;
  cursor: pointer;
}

/* Clients css */
.clients-container {
  height: 54vh;
  width: 100%;
  background-color: #ffffff;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.client-heading {
  color: #37466f;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.client-image img {
  height: 130px;
  width: 130px;
  object-fit: contain;
}

.client-carousel-container {
  width: 100%;
  margin: auto;
  /* margin-top: 70px; */
}

.client-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.client-image {
  padding: 10px;
  flex: 0 0 20%; /* 5 images per row */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .client-image img {
  width: 100%;
  height: auto;
  display: block;
} */

/* Our Presence CSS*/
.presence-container {
  background-color: #eeeeee;
  height: 100vh;
  width: 100%;
  padding: 60px 80px 50px;
}

.presence-heading {
  color: #37466f;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.presence-navbar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  position: relative;
}

.presence-navbar {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  width: 35%;
  padding: 5px 8px;
}

.presence-navbar-loc .nav-item {
  gap: 10px;
  /* width: 20%; */
  padding: 0;
  margin-bottom: 30px;
  font-size: 13px;
}

.nav-item {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  color: #37466f;
  letter-spacing: 1px;
}

.horizontal-line {
  width: 100%;
  height: 2px;
  background-color: #37466f4a;
  position: absolute;
  top: 47px;
  border-radius: 10px;
}

.nav-item.active {
  border-bottom: 2.5px solid #37466f;
  border-radius: 2px;
}

.presence-map-section {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 137px;
  padding-top: 19px;
}

.presence-map-section-india {
  align-items: flex-start;
}

.presence-map-left {
  width: 32%;
}

.presence-address-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.presence-address-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.presence-address-heading span {
  font-size: 19px;
  color: #37466f;
  font-weight: bold;
}

.presence-address {
  font-size: 17px;
  color: #37466f;
  text-align: left;
}

.presence-map-right {
  padding-top: 20px;
  width: 700px;
  position: relative;
}

.presence-map-right img {
  width: 100%;
  height: auto;
}

.map-legend {
  width: 150px;
  position: absolute;
  bottom: 72px;
  right: 147px;
}

/* Contact us CSS */
.contactUs-container {
  height: 100vh;
  width: 100%;
  background-color: #faf9f9;
  padding: 60px 80px 50px;
}

.contactUs-inner-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.contactUs-left {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;
}

.contactUs-sub-heading {
  font-size: 33px;
  font-weight: bold;
  color: #37466f;
  text-align: left;
}

.contactUs-right {
  width: 50%;
  text-align: left;
}

.red-star {
  color: #e22323;
  font-weight: bold;
}

.flag-icon {
  height: 25px;
  cursor: pointer;
  margin-right: 3px;
}

.right-nav-abc {
  position: relative;
  justify-content: center !important;
  align-items: center !important;
}

.flags-dropdown {
  position: absolute;
  top: 65px;
  right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  font-size: 15px;
  background-color: whitesmoke;
  opacity: 1;
  padding: 8px 10px;
  border-radius: 3px;
  font-weight: bold;
}

.down-icon {
  height: 12px;
  cursor: pointer;
}

.flag-icon-option {
  width: 100%;
  text-align: start;
}

.flag-icon-option .flag-icon {
  height: 20px;
}

.flag-icon-option:hover {
  background-color: #c4c4c4;
  cursor: pointer;
}

.selectd-flag {
  background-color: #c4c4c4;
}

.company-footer {
  background-color: #37466f;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.navbar-expand-lg .navbar-collapse {
  padding: 0px 30px;
}

.navbar {
  width: 100%;
  position: absolute !important;
  z-index: 999;
  transition: top 0.3s;
  background-color: rgba(255, 255, 255);
  opacity: 0.98;
  color: black;
}

.nav-links {
  gap: 45px;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fc7171;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 !important;
}

.navbar.active {
  background-color: white;
  box-shadow: 0px 2px 6px #8d8d8d29;
  position: fixed !important;
  transition: top 0.5s;
  height: 50px;
  opacity: 1;
}

.navbar.active .nav-link {
  color: black;
  font-weight: normal;
}

.navbar.active .nav-link:hover {
  color: #fc7171;
}

.navbar.active .nav-link .aeonLogo {
  height: 40px;
  width: 40px;
  padding: 3px 0px;
}

.navbar.active .flags-dropdown {
  top: 40px;
}

.logout-btn {
  position: absolute;
  color: white;
  right: 20px;
  top: 20px;
  font-size: large;
  cursor: pointer;
  padding: 2px 10px;
  border: 1px solid transparent;
}

.logout-btn:hover {
  border: 1px solid white;
  border-radius: 10px;
}

.second-banner-logo {
  position: absolute;
  top: 10px;
  left: 10px;
}

.second-banner-logo img {
  height: 40px !important;
}

/* continous slider test */
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 30s slide infinite linear;
}

.logos-slide img {
  height: 100px;
  margin: 0 60px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .home-container {
    background-size: auto;
    height: 56vh;
  }

  .home-container .inner-container h1 {
    font-size: 15px;
  }

  .inner-container span {
    width: 100%;
    font-size: 11px;
    text-align: left;
  }

  .inner-container {
    bottom: 62px;
    left: 26px;
  }

  .discover-now-btn .step {
    height: 30px;
    width: 30px;
  }

  .step svg {
    width: 20px;
  }

  .discover-now-btn .step:hover {
    width: 113px;
  }

  .step:hover .discover-now-text {
    font-size: 13px;
  }

  .product-box-container {
    flex-direction: column;
  }

  .products-container {
    padding: 22px 25px 60px;
  }

  .products-inner-container {
    margin-top: 23px;
  }

  .product-box {
    padding: 20px;
    height: 390px;
  }

  .product-box svg {
    width: 46px;
  }

  .product-box-inner {
    gap: 11px;
  }

  .product-box-inner span {
    font-size: 15px;
  }

  .product-box-inner div {
    font-size: 14px;
  }

  .product-box a {
    font-size: 14px;
  }

  .aboutUs-container h3 {
    font-size: 15px;
  }

  .aboutUs-container .sub-heading {
    font-size: 16px;
    margin-bottom: 18px;
  }

  .aboutUs-inner-container {
    padding: 26px 19px;
  }

  .aboutUs-inner-container .aboutUs-left p {
    font-size: 12px;
  }

  .explore-more a {
    font-size: 12px;
    padding: 3px 12px;
  }

  .aboutUs-right {
    display: none;
  }

  .aboutUs-container {
    height: 54vh;
  }

  .domains-container {
    height: 100vh;
    padding-bottom: 0px;
  }

  .domain-heading {
    padding-top: 0px;
    font-size: 17px;
  }

  .domain-first {
    padding: 20px;
    gap: 20px;
    height: auto;
  }

  .domain-second {
    padding: 20px;
    gap: 20px;
    height: auto;
  }

  .domain-third {
    padding: 20px;
    gap: 20px;
    height: auto;
  }

  .domain img {
    height: 75px;
    border-radius: 0px 0px 0px 22px;
  }

  .domain-right1-inner {
    height: 75px;
    padding: 10px;
    justify-content: space-around;
  }

  .domain-right1-inner h4 {
    font-size: 14px;
    margin-bottom: 0px !important;
  }

  .domain-right1-inner p {
    display: none;
  }

  .domain-right1 a {
    font-size: 9px;
  }

  .domain svg {
    width: 8px;
  }

  .domain-right1 span {
    gap: 4px;
  }

  .domain-left1-inner h4 {
    font-size: 14px;
    margin-bottom: 0 !important;
  }

  .domain-left1-inner p {
    display: none;
  }

  .domain-left-conatiner img {
    border-radius: 0px 22px 0px 0px;
  }

  .domain-left1-inner {
    height: 75px;
    padding: 10px;
    justify-content: space-around;
  }

  .domain-left1 a {
    font-size: 9px;
  }

  .domain-right1 {
    width: 132px;
  }

  .service-heading {
    padding-top: 0px;
    font-size: 17px;
  }

  .services-container {
    height: auto;
    gap: 36px;
  }

  .services-blog {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 40px 10px; */
  }

  .service .polygonImg {
    /* height: 168px; */
  }

  .service .polygonService {
    /* height: 80px; */
  }

  .service .small-service-img {
    /* height: 94px; */
  }

  .service {
    width: auto;
  }

  .service img {
    height: 60px;
  }

  .service span {
    font-size: 13px;
    width: 100%;
  }

  .service {
    gap: 7px;
  }

  .clients-container {
    padding: 20px;
    height: 20vh;
    margin-bottom: 20px;
  }

  .client-heading {
    font-size: 17px;
  }

  .client-image {
    flex: 0 0 20%;
  }

  .client-image img {
    height: auto;
    width: 200px;
  }

  .presence-container {
    padding: 22px 25px 60px;
  }

  .presence-heading {
    font-size: 17px;
  }

  .presence-navbar {
    gap: 6px;
    width: auto;
  }

  .nav-item {
    font-size: 10px;
  }

  .horizontal-line {
    top: 40px;
  }

  .presence-container {
    height: auto;
  }

  .presence-navbar-loc .nav-item {
    font-size: 9px;
    margin-right: 5px;
    margin-bottom: 0px;
  }

  .presence-map-section {
    padding-top: 13px;
    flex-direction: column;
    gap: 30px;
  }

  .presence-address-heading span {
    font-size: 13px;
    text-align: left;
  }

  .presence-address {
    font-size: 13px;
  }

  .presence-map-left {
    width: 100%;
  }

  .presence-map-right {
    width: 100%;
  }

  .contactUs-inner-container {
    flex-direction: column;
  }

  .contactUs-container {
    padding: 22px 25px 60px;
    height: auto;
  }

  .contactUs-sub-heading {
    font-size: 14px;
  }

  .contactUs-right .form-label {
    font-size: 13px;
  }

  .contactUs-left {
    width: 100%;
    gap: 10px;
  }
  .contactUs-right {
    width: 100%;
    margin-top: 20px;
  }

  .contactUs-right .row .col-lg-6 {
    margin-bottom: 0px !important;
  }

  .company-footer {
    flex-direction: column;
    gap: 5px;
    padding: 10px 0px;
    height: auto;
    font-size: 10px;
  }

  .logout-btn {
    right: 11px;
    top: 9px;
    font-size: 14px;
  }

  .second-banner-logo img {
    height: 27px !important;
  }

  .logos:before,
  .logos:after {
    width: 50px;
  }

  .logos-slide img {
    height: 70px;
    margin: 0px 30px;
  }

  .carousel-item {
    height: 56vh !important;
  }

  .carousel {
    height: 56vh !important;
  }

  .carousel-item img {
    height: 59vh;
  }

  .carousel-caption {
    top: 14rem !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .nav-links {
    gap: 35px;
  }

  .home-container {
    background-size: auto;
  }

  .inner-container {
    bottom: 165px;
    left: 80px;
  }

  .aboutUs-right img {
    height: 70%;
  }

  .presence-container {
    height: 85vh;
  }

  .services-blog {
    gap: 0;
  }

  .service {
    width: 190px;
  }

  .service img {
    height: 100px;
  }

  .product-box {
    height: 450px;
    padding: 40px;
  }

  .client-image img {
    height: 90px;
    width: 90px;
  }

  /* .clients-container {
    height: calc(100vh - 143px);
  } */

  .presence-address-heading span {
    font-size: 15px;
  }

  .presence-address {
    font-size: 14px;
  }

  .contactUs-sub-heading {
    font-size: 30px;
  }

  .company-footer {
    font-size: 15px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .home-container {
    background-size: auto;
  }

  .inner-container {
    bottom: 168px;
  }

  .inner-container span {
    font-size: 20px;
  }

  .aeonLogo {
    height: 85px;
    width: 85px;
  }

  .aboutUs-right img {
    height: 65%;
  }

  .domains-container {
    height: 800px;
  }

  .domain-first {
    justify-content: space-evenly;
    /* gap: 127px; */
  }

  .domain-third {
    gap: 135px;
    /* justify-content: space-evenly; */
  }

  .ecomm-img {
    gap: 53px;
  }

  .clients-container {
    height: 42vh;
  }

  .presence-container {
    height: 84vh;
  }

  .contactUs-container {
    height: 80vh;
  }

  .services-container {
    height: calc(100vh - 50px);
    gap: 100px;
  }

  .carousel-item img {
    height: 105vh;
  }

  .carousel-caption {
    top: 24rem !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .aeonLogo {
    height: 100px;
    width: 100px;
  }

  .nav-links {
    font-size: 25px;
  }

  .flag-icon {
    height: 35px;
  }

  .down-icon {
    height: 16px;
  }

  .flags-dropdown {
    top: 92px;
    right: 25px;
    font-size: 20px;
  }

  .flag-icon-option .flag-icon {
    height: 30px;
  }

  .inner-container {
    bottom: 190px;
  }

  .inner-container h1 {
    font-size: 50px;
  }

  .inner-container span {
    font-size: 30px;
  }

  .discover-now-btn .step {
    width: 80px;
    height: 80px;
  }

  .step svg {
    height: 50px;
    width: 50px;
  }

  .aboutUs-left .sub-heading {
    font-size: 40px;
  }

  .aboutUs-left h3 {
    font-size: 30px;
  }

  .aboutUs-left p {
    font-size: 25px;
  }

  .aboutUs-right img {
    height: 72%;
  }

  .explore-more a {
    font-size: 20px;
  }

  .navbar.active {
    height: 70px;
  }

  .navbar.active .nav-link .aeonLogo {
    height: 50px;
    width: 50px;
  }

  .domains-container {
    height: calc(1080px - 70px);
  }

  .domain-heading {
    font-size: 40px;
  }

  .domain img {
    height: 360px;
  }

  .domain-right1 {
    width: 1183px;
  }

  .domain-right1-inner {
    height: 100%;
    padding: 32px;
  }

  .domain-right1-inner .mb-3 {
    font-size: 35px;
  }

  .domain-right1-inner p {
    font-size: 25px;
  }

  .domain-right1 a {
    font-size: 20px;
  }

  .domain-right1 svg {
    height: 30px;
    width: 30px;
  }

  .domain-left1-inner {
    height: 360px;
    padding: 32px;
  }

  .domain-left1-inner .mb-3 {
    font-size: 35px;
  }

  .domain-left1 {
    width: 1183px;
  }

  .domain-left1-inner p {
    font-size: 25px;
  }

  .domain-left1 a {
    font-size: 20px;
  }

  .domain-left1 svg {
    height: 30px;
    width: 30px;
  }

  .services-container {
    height: calc(1080px - 70px);
    justify-content: flex-start;
    gap: 110px;
  }

  .service-heading {
    font-size: 40px;
  }

  .service {
    width: 200px;
  }

  .service img {
    height: 170px;
  }

  .service span {
    font-size: 30px;
  }

  .service span {
    width: 400px;
  }

  .services-blog {
    gap: 150px;
  }

  .products-container {
    padding: 70px 80px 80px;
  }

  .product-box {
    height: 560px;
    padding: 50px 60px;
  }

  .product-box-inner span {
    font-size: 27px;
  }

  .product-box-inner div {
    font-size: 25px;
  }

  .product-box a {
    font-size: 25px;
  }

  .product-box-inner {
    gap: 25px;
  }

  .product-box svg {
    height: 90px;
    width: 90px;
  }

  .client-heading {
    font-size: 40px;
  }

  .clients-container {
    height: 40vh;
  }

  .presence-container {
    height: calc(1080px - 176px);
  }

  .nav-item {
    font-size: 27px;
  }

  .horizontal-line {
    top: 66px;
  }

  .presence-navbar-loc .nav-item {
    font-size: 20px;
  }

  .presence-address-heading span {
    font-size: 25px;
  }

  .presence-address {
    font-size: 25px;
  }

  .contactUs-right label {
    font-size: 25px;
  }

  .contactUs-container {
    height: calc(1080px - 200px);
  }
}
